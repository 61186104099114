<template>
  <div class="production_wrap">
    <div class = "box">
      <div class="about-title">行业新闻</div>
      <div class = "block-line"></div>
      <div class="about-container">
        <ul class = "news-list">
          <li class="list-item" v-for="item in listData" :key="item.id" @click="openNewsDetail(item)">
            <span class="title"><i class="el-icon-caret-right" style="margin-right: 5px;"></i>{{ item.caption }}</span>
            <span class="time">{{ changeTime(item.time) }}</span>
          </li>
        </ul>
      </div>
    </div>
    <CallMe />
    <MobileCallme />
  </div>
</template>

<script>
import axios from 'axios'
import CallMe from '@/components/callme/index'
import MobileCallme from '@/components/callme/MobileCallme.vue'
export default {
  data() {
    return {
      listData: []
    }
  },
  components: {
    CallMe,
    MobileCallme,
  },
  mounted () {
    this.initIntro()
  },
  methods: {
    initIntro () {
      // 获取列表
      var _this = this
      _this.list = []
      axios.get('https://app.dtspd.com/miniodata/dalianportal/dalian_hyxw.json')
        .then(response => {
          var data = response.data
          const sortedData = data.sort((a, b) => b.id - a.id)
          _this.listData = sortedData
        })
        .catch(error => {
          console.log(error)
        })
    },
    changeTime (val) {
      const dateParts = val.split('/')
      const newFormatDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
      return newFormatDate
    },
    openNewsDetail (item) {
      if (item.url) {
        window.open(item.url, '_blank')
      } else {
        this.$message('请等待数据更新，谢谢！')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.production_wrap {
  padding-top: 200px;
  width: 100%;
}
</style>